<template>
  <div class="deviceConfigList-wrapper">
    <list ref="list"
          exportPermission="export"
          exportMethod="delay"
          :searchUrl="searchUrl"
          :exportUrl="exportUrl"
          :searchParams.sync="searchParams"
          :headers="headers">
      <template #headerSlot>
        <v-button text="新增"
                  permission="add"
                  @click="addRoadGate"></v-button>
      </template>
      <template #searchSlot>
        <v-select2 label="项目名称"
                   v-model="searchParams.communityId"
                   v-bind="communityParams"></v-select2>
        <v-input label="苑"
                 v-model="searchParams.blockName"></v-input>
        <v-input label="停车场名称"
                 v-model="searchParams.parkingName"></v-input>
        <v-input label="停车场厂商名称"
                 v-model="searchParams.manufacturerName"></v-input>
        <v-datepicker label="最后操作时间"
                      :startTime.sync="searchParams.startTime"
                      :endTime.sync="searchParams.endTime"
                      :maxDate="maxDate"></v-datepicker>
        <v-select label="启用状态"
                  v-model="searchParams.status"
                  :options="statusOps"></v-select>
        <v-input label="操作人"
                 v-model="searchParams.updateUser"></v-input>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑"
                  type="text"
                  permission="update"
                  @click="editRoadGate(scope.row)"></v-button>
        <el-dropdown trigger="click"
                     @command="moreHandle">
          <span class="el-dropdown-link">
            更多<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="{ row: scope.row, type: 1 }">
              <span v-text="scope.row.status === '1' ? '关闭' : '开启'"></span>
            </el-dropdown-item>
            <el-dropdown-item v-if="scope.row.status === '1'"
                              :command="{ row: scope.row, type: 2 }">
              <span>生成二维码</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </list>
    <el-dialog title="下载二维码"
               width="500px"
               :visible.sync="dialogQrcode"
               @closed="closeDialog">
      <div class="qrcode-wrapper">
        <div>
          <h3 class="title-text">
            项目：{{ qrCodeObj.communityName }}
          </h3>
        </div>
        <div class="qrcode-value">
          <span> 苑：{{ qrCodeObj.blockName }} </span>
        </div>
        <div class="qrcode-value">
          <span> 停车场名称：{{ qrCodeObj.parkingName }} </span>
        </div>
        <div class="qrcode-img-wrapper">
          <div v-if="qrCodeId"
               :id="qrCodeId"
               class="img"></div>
        </div>
        <v-button text="保存到本地"
                  type="success"
                  @click="downloadQRCode"></v-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getListURL,
  exportListURL,
  eidtStatus
} from './api'
import {
  statusOps,
  statusMap
} from './map'
import QRCode from 'qrcodejs2'
import { downloadHelper, _localStorage } from 'common/utils'
import { Dropdown, DropdownMenu, DropdownItem } from 'element-ui'
import { communityParams } from 'common/select2Params'

export default {
  name: 'DeviceConfigList',
  components: {
    elDropdown: Dropdown,
    elDropdownMenu: DropdownMenu,
    elDropdownItem: DropdownItem
  },
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      statusOps: statusOps(1),
      maxDate: new Date(),
      communityParams,
      areaOps: [
        {
          text: '全部',
          value: undefined
        }
      ],
      extraParams: {},
      searchParams: {
        communityId: '',
        blockName: '',
        parkingName: '',
        manufacturerName: '',
        status: undefined,
        startTime: '',
        endTime: '',
        updateUser: ''
      },
      headers: [
        {
          prop: 'communityName',
          label: '项目'
        },
        {
          prop: 'blockName',
          label: '苑'
        },
        {
          prop: 'parkingName',
          label: '停车场名称'
        },
        {
          prop: 'manufacturerName',
          label: '停车场厂商名称'
        },
        {
          prop: 'status',
          label: '启用状态',
          formatter (row) {
            return statusMap[row.status]
          }
        },
        {
          prop: 'updateTime',
          label: '最后操作时间'
        },
        {
          prop: 'updateUserName',
          label: '操作人'
        }
      ],
      qrCodeId: '',
      qrCodeObj: {
        name: '',
        code: '',
        address: ''
      },
      dialogQrcode: false,
      dialogQrcodeList: false,
      qrcodeList: []
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
    // 保存二维码到本地
    downloadQRCode () {
      // downloadHelper
      let qrcodeEle = document.getElementById(this.qrCodeId)
      let url = qrcodeEle.children[1].src
      downloadHelper.downloadBySrc({
        src: url,
        fileName: this.qrCodeObj.parkingName
      })
      this.dialogQrcode = false
    },
    moreHandle (val) {
      if (val.type === 1) {
        this.switchStatus(val.row)
      } else if (val.type === 2) {
        this.createQrcode(val.row)
      }
    },
    async switchStatus (row) {
      let status = ''
      let result = true
      if (row.status === '1') {
        status = 0
        let confirmText = '关闭此道闸设施后将立即生效，是否继续？'
        result = await this.$confirm(confirmText)
      } else {
        status = 1
      }
      if (result) {
        let res = await this.$axios({
          url: eidtStatus,
          params: {
            id: row.id,
            status
          },
          method: 'post',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
        if (res.status === 100) {
          this.$message.success('操作成功')
          this.$refs.list.searchData()
        }
      }
    },
    createQrcode (row) {
      let companyCode = _localStorage.getItem('companyCode')
      let data = `${API_CONFIG.h5BaseURL}h5/community/tmpPark/index.html?communityId=${row.communityId}&yuanName=${encodeURIComponent(row.blockName)}&companyCode=${companyCode}#/index`
      this.qrCodeObj = row
      this.dialogQrcode = true
      this.qrCodeId = `img_${data}`
      this.$nextTick(() => {
        new QRCode(this.qrCodeId, { // eslint-disable-line
          width: 200,
          height: 200,
          text: data
        })
      }, 20)
    },
    closeDialog () {
      this.qrCodeId = ''
    },
    addRoadGate () {
      this.$router.push({
        name: 'roadGateForm'
      })
    },
    editRoadGate (row) {
      this.$router.push({
        name: 'roadGateForm',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>
<style scope lang="scss">
.deviceConfigList-wrapper {
  .qrcode-wrapper {
    display: block;
    .title-text {
      margin-top: -10px;
      margin-bottom: 10px;
      padding-bottom: 10px;
      text-align: center;
      font-size: 24px;
      border-bottom: 1px dotted #333;
    }
    .qrcode-value {
      padding: 10px;
      text-align: center;
    }
    .qrcode-img-wrapper {
      display: flex;
      justify-content: center;
      text-align: center;
      padding: 10px 0;
    }
  }
}
</style>
